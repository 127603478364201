const styles = {
	footer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		minHeight: 80,
		backgroundColor: 'white',
		borderTop: '1px solid #E6E6E6',
		'@media only screen and (max-width: 480px)': {
			paddingTop: 20,
		},
		'.container': {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			'@media only screen and (max-width: 480px)': {
				flexDirection: 'column-reverse',
			},
		},
	},

	copyright: {
		a: {
			color: 'primary',
			fontWeight: '700',
			textDecoration: 'none',
		},
		'@media only screen and (max-width: 480px)': {
			p: {
				marginTop: '5px',
			},
		},
	},
	about: {
		textDecoration: 'none',
		color: 'primary' ,
		'@media only screen and (min-width: 1081px)': {
			'&:hover': {
				color: 'black',
				textDecoration: 'underline',
			},
			'&.active': {
				color: 'black',
				fontWeight: '600',
			},
		},	
		'@media only screen and (max-width: 1080px)': {
			'&:hover, &.active': {
				color: 'black',
				fontWeight: '600',
			},
		},		
		a: {
			color: 'primary' ,
			fontSize: '14px',
			position: 'relative',
			'@media only screen and (min-width: 1081px)': {
				'&:hover': {
					color: 'black',
					textDecoration: 'underline',
				},
				'&.active': {
					color: 'black',
					fontWeight: '600',
				},
			},
			'@media only screen and (max-width: 1080px)': {
				'&:hover, &.active': {
					color: 'black',
					fontWeight: '600',
				},
			},
		},
	},		
};

export default styles;
